import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";


export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "shop-post",
    data() {
        return {
            shopPost: {

                title: "",
                description: "",
                numberOfCoupons: null,
                price: ""
            },
            discountPercentage: null,
            requiredRule: [v => !!v || "Field is required"],
            isSaving: false,
            loading: false,
            shopPostId: null,
            shopPostTitle: "",
        };
    },
    async created() {
        this.loading = true;
        this.shopPostId = this.$route.params.shopPostId;
        this.shopPostTitle = this.$route.params.shopPostTitle;
        this.$log.debug("shopPostId: ", this.shopPostId);
        this.$store.dispatch(SET_BREADCRUMB, [{title: this.$i18n.t('pages.shopPosts.detailsForShopPost') + this.shopPostId}]);
        this.retrieveShopPostDetails(this.shopPostId);
    },

    methods: {
        retrieveShopPostDetails(shopPostId) {
            if (shopPostId != null) {
                this.loading=true;
                ApiService.get(`api/shop-posts/${shopPostId}`)
                .then(response => {
                    this.$log.debug("Shop post: ", response.data);
                    this.shopPost = response.data;
                    this.discountPercentage = response.data.discount * 100;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
            }
        },
        previousState() {
            this.$router.go(-1);
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.shopPost.discount = this.discountPercentage / 100;
                ApiService.put(`api/shop-posts`, this.shopPost)
                    .then(response => {
                        this.$log.debug("Shop updated: " + response);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            }
        },
    },
    components: {
        ConfirmDialog
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
